import React from "react";
import cx from "classnames";

export const Container = ({children, fluid = false}) => <div className={cx({container: !fluid}, {"container-fluid": fluid})}>{children}</div>;
export const Row = ({children}) => <div className='row'>{children}</div>;

export const ContainerRow = ({children, fluid = false}) => (
  <Container fluid={fluid}>
    <Row>{children}</Row>
  </Container>
);

export const ContainerFullCol = ({children}) => (
  <Container>
    <Row>
      <div className='col-12'>{children}</div>
    </Row>
  </Container>
);
