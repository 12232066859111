import React from "react";
import cx from "classnames";
import style from "./CentralLayout.module.scss";
import {ContainerRow} from "../Containers/Conainers";

const CentralLayout = ({children}) => (
  <ContainerRow>
    <div className={cx("col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2", style.container)}>{children}</div>
  </ContainerRow>
);

export default CentralLayout;
