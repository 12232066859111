import React from "react";
import style from "./PageNotFound.module.scss";
import cx from "classnames";
import {ContainerRow} from "../../atoms/Containers/Conainers";
import Section from "../../atoms/Section/Section";
import CentralLayout from "../../atoms/CentralLayout/CentralLayout";
import Button from "../../elements/Buttons/Button";
import Text from "../../atoms/Text/Text";

const PageNotFound = () => (
  <Section>
    <ContainerRow>
      <div className={cx(style.left, "col-12 col-md-6")}>
        <CentralLayout>
          <h1 className={cx("mb-10", style.title)}>404</h1>
          <Text className>Sembra che tu abbia fatto male i calcoli...</Text>
          <Button to='/' customClass='mt-8' black>
            Home
          </Button>
        </CentralLayout>
      </div>
      <div className={cx("col-6 d-none d-md-block", style.image)} />
    </ContainerRow>
  </Section>
);

export default PageNotFound;
