import React from "react";
import Page from "../components/Page";
import Space from "../components/elements/Space/Space";
import PageNotFound from "../components/components/404/PageNotFound";
import Navigation from "../components/layout/Navigation/Navigation";

const PageNotFoundPage = () => (
  <Page title='404' description='' image='/favicon.png'>
    <Navigation />
    {/* navigation space */}
    <Space mobile={15} table={15} desktop={15} />

    <PageNotFound />
  </Page>
);

export default PageNotFoundPage;
